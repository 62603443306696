import React from "react";
import * as Yup from "yup";

import { Formik, Form, Field, ErrorMessage } from "formik";
import "./Contact.css";

const API_BASE_URL = "https://dmf.motownsmart.com"; // Replace with your actual API base URL

function Contact() {
  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    phone: Yup.number().typeError("Invalid phone number").required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    message: Yup.string(),
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  return (
    <div id="contact" className="contactFormContainer">
      <div className="contactInfo">
        <h2>Let's get </h2>
        <h2 style={{ marginBottom: "20px" }}>in touch.</h2>

        <p style={{ maxWidth: "430px" }}>
          Ask a question, get a charity started, or learn more about who we help
          and how.
        </p>
        <p style={{ maxWidth: "430px" }}>
          Fill out the form below, and DMF will be in touch with you as soon as
          possible.
        </p>
      </div>
      <div className="formContainer">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          // onSubmit={onSubmit}
          onSubmit={(values, actions) => {
            // Send data to Netlify Forms
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({ "form-name": "contact", ...values }),
            })
              .then(() => {
                console.log("Form data sent to Netlify Forms successfully");
              })
              .catch(() => {
                console.error("Error sending form data to Netlify Forms");
              });

            // Send data to your backend API
            fetch(`${API_BASE_URL}/api/forms`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(values),
            })
              .then(() => {
                console.log("Form data sent to backend successfully");
                actions.resetForm();
              })
              .catch(() => {
                alert("Error sending form data to backend");
              })
              .finally(() => actions.setSubmitting(false));
          }}
        >
          <Form name="contact" data-netlify={true}>
            {/* <input type="hidden" name="contact" value="contact" /> */}

            <div className="inputFieldContact">
              <label
                htmlFor="firstName"
                style={{
                  display: "none",
                }}
              >
                First Name
              </label>
              <Field
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="error"
              />
            </div>

            <div className="inputFieldContact">
              <label
                htmlFor="lastName"
                style={{
                  display: "none",
                }}
              >
                Last Name
              </label>
              <Field
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
              />
              <ErrorMessage name="lastName" component="div" className="error" />
            </div>

            <div className="inputFieldContact">
              <label
                htmlFor="phone"
                style={{
                  display: "none",
                }}
              >
                Phone
              </label>
              <Field type="text" id="phone" name="phone" placeholder="Phone" />
              <ErrorMessage name="phone" component="div" className="error" />
            </div>

            <div className="inputFieldContact">
              <label
                htmlFor="email"
                style={{
                  display: "none",
                }}
              >
                Email
              </label>
              <Field type="email" id="email" name="email" placeholder="Email" />
              <ErrorMessage name="email" component="div" className="error" />
            </div>

            <div className="messageField">
              <label
                htmlFor="message"
                style={{
                  display: "none",
                }}
              >
                Message
              </label>
              <Field
                as="textarea"
                id="message"
                name="message"
                placeholder="Message"
              />
              <ErrorMessage name="message" component="div" className="error" />
            </div>

            <button type="submit">SUBMIT</button>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default Contact;
