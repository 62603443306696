import React, { useContext, useEffect } from "react";

import "./Navbar.css";
import dmfLog from "../images/dmfLog.svg";
import burgerBlue from "../images/burgerBlue.svg";
import rightArrow from "../images/rightArrow.png";

import { ThemeContext } from "../utils/NavbarContextProvider";
function Navbar() {
  const { isNavBarOpen, setIsNavBarOpen, setIsManuallyClosed } =
    useContext(ThemeContext);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 800) {
        setIsNavBarOpen(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  function handleClose() {
    setIsManuallyClosed(false);
    setIsNavBarOpen((prev) => !prev);
  }

  function handleLinkClick() {
    setIsNavBarOpen(false);
  }

  const handleDonateClick = () => {
    setIsNavBarOpen(false);
    const donateSection = document.getElementById("donate");
    if (donateSection) {
      donateSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = () => {
    window.location.href = "https://dmfcares.org/";
  };

  const handleContactClick = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
      setIsNavBarOpen(false);
    }
  };

  return (
    <section
      style={{
        maxWidth: "1900px",
        margin: "0 auto",
        width: "100%",
      }}
    >
      <nav className="navbar">
        <div
          className="navbar-logo"
          style={{
            width: "11rem",
          }}
        >
          <img
            src={dmfLog}
            alt="Logo"
            style={{
              width: "80%",
              cursor: "pointer",
            }}
            onClick={handleClick}
          />
        </div>

        <ul className={`navbar-links ${isNavBarOpen ? "show" : "hide"}`}>
          <li className="navbarLink">
            <a
              href="https://dmfcares.org/#programs"
              target="_blank"
              rel="noopener noreferrer"
            >
              PROGRAMS
            </a>
          </li>
          <li className="navbarLink" onClick={handleLinkClick}>
            <a
              href="https://dmfcares.org/#partner"
              target="_blank"
              rel="noopener noreferrer"
            >
              PARTNER
            </a>
          </li>
          <li className="navbarLink" onClick={handleContactClick}>
            <a
              href="https://dmfcares.org/#contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              CONTACT
            </a>
          </li>

          {window.location.pathname.includes("success") ? (
            <>
              <li className="navbarLinkDonate" onClick={handleDonateClick}>
                <a
                  href="https://donate.dmfcares.org/#donate"
                  rel="noopener noreferrer"
                >
                  DONATE
                  <img
                    src={rightArrow}
                    alt="right arrow"
                    className="navbarLinkImg"
                  />
                </a>
              </li>
              <img
                src={rightArrow}
                alt="right arrow"
                className="navbarLinkImg"
              />
            </>
          ) : (
            <>
              <li className="navbarLinkDonate" onClick={handleDonateClick}>
                <a href="#donate">DONATE</a>
              </li>
              <img
                src={rightArrow}
                alt="right arrow"
                className="navbarLinkImg"
              />
            </>
          )}

          {/* <li className="navbarLinkDonate" onClick={handleDonateClick}>
            <a href="#donate">DONATE</a>
          </li> */}
        </ul>

        <div className="navbar-menu-icon" onClick={handleClose}>
          <img
            style={{
              width: "45px",
              height: "40px",
            }}
            src={burgerBlue}
            alt="Menu Icon"
          />
        </div>
      </nav>
    </section>
  );
}

export default Navbar;
